*:not(i),
.ui,
.ui.header {
    font-family: 'Inter', sans-serif !important;
}

a:hover {
    color: #22b99a !important;
}

body.pushable .pusher {
    background: #fff !important;
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .ui.container {
        width: 1050px !important;
    }
}

@media (min-width: 1500px) {
    .ui.container {
        width: 1340px !important;
    }
}

.ui.menu {
    position: relative;
    border: 0 !important;
    border-radius: 0;
    box-shadow: none !important;
}

.ui.menu .item {
    padding: 20px;
}

.top-menu .ui.menu a.item:hover {
    color: #fff !important;
    background: #0000001A !important;
}

.top-bar {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .top-bar {
        flex-direction: row;
        align-items: center;
    }
}

.top-bar > div {
    margin: 0 !important;
}

.top-bar > .item {
    padding: 10px 20px;
    color: #fff;
    white-space: nowrap;
}

.top-bar > .item:hover {
    background: #0000001A;
}

.top-bar .dropdown .menu {
    background: #fff !important;
}

header a.item:hover {
    color: inherit !important;
}

.ui.menu .item:before {
    display: none;
}

.pusher > #menu {
    position: relative;
    width: 1340px !important;
    max-width: 100%;
    margin: 0 auto !important;
    background: transparent !important;
}

.pusher > #menu .menu {
    background: none;
}

.pusher > #menu .menu .item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background: none;
}

.pusher > #menu::after {
    all: unset;
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 300%;
    height: 600px;
    z-index: -1;
    background: #22b99a;
}

.pusher > .container > header > .segment {
    padding: 25px 0 16px 0 !important;
}

header .ui.menu {
    background: #f5f5f5;
    justify-content: center;
}

header .ui.menu::before {
    all: unset;
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 1px 1px #eaeaea;
}

header .ui.three.column.stackable.grid > .column {
    width: auto !important;
}

header .ui.three.column.stackable.grid > .column:nth-child(2) {
    flex-grow: 1;
}

header .ui.three.column.stackable.grid > .column:nth-child(3) .button {
    background: none;
    box-shadow: 0 0 0 1px #00000015;
}

header .ui.three.column.stackable.grid > .column:nth-child(3) .button .icon {
    color: #22b99a;
}

header .ui.three.column.stackable.grid > .column:nth-child(3) .button span {
    color: #252525;
    font-weight: 500;
}

.ui.breadcrumb {
    margin-left: 0 !important;
    line-height: 1.8;
}

.ui.breadcrumb a {
    color: #22b99a;
}

.ui.breadcrumb .active {
    color: inherit;
    font-weight: 400 !important;
}

@media (max-width: 767px) {
    .ui.breadcrumb {
        padding: 0 20px;
    }
}

#products.three.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 30px;
    margin: 0 !important;
}

@media (min-width: 1200px) {
    #products.three.cards {
        grid-template-columns: repeat(3, minmax(240px, 1fr));
    }
}

#products.three.cards .card {
    width: unset;
    margin: 0 !important;
}

.card {
    box-shadow: none !important;
    background: transparent !important;
}

.card .image {
    background: transparent !important;
}

.card .image,
.card .image .dimmer {
    border-radius: 6px !important;
    overflow: hidden !important;
}

.card .content {
    padding: 20px 0 !important;
}

.sylius-product-name {
    font-size: 1.2em !important;
    margin: 5px 0;
}

.card .sylius-product-price {
    font-size: 1.2em !important;
    margin: 5px 0;
}

.card .sylius-product-price,
.card .sylius-product-original-price {
    display: inline-block;
}

.promotion_label {
    background-color: #22b99a !important;
    color: #fff !important;
}

.card .content .promotion_label {
    position: absolute;
    top: 10px;
    right: 20px;
}

.button {
    border-radius: 5px !important;
    font-weight: 400 !important;
}

.primary.button {
    background: #22b99a !important;
}

.huge.button {
    font-size: 1.1rem !important;
}

.ui.icon.labeled.button {
    padding: 16px 50px 16px 73px !important
}

.ui.icon.labeled.button i {
    background: none !important;
    width: 80px !important;
}

.ui.large.comments {
    max-width: unset;
}

.ui.large.comments .comment {
    width: 100%;
    padding: 25px;
    border: 1px solid #eee;
    border-radius: 6px;
}

.ui.header > .icon {
    display: none;
}

.ui.header > .icon + .content {
    font-size: 1.2em;
    padding: 0;
}

.ui.header > .icon + .content .sub.header {
    margin-top: 10px;
    line-height: 1.7;
}

.ui.items>.item>.content {
    padding: 3px;
}

.sylius-flash-message {
    margin: 3em 0 1em 0 !important;
}

.cart.popup .item {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

@media (max-width: 799px) {
    .top-menu {
        display: block;
    }
}

@media (min-width: 800px) {
    .top-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width: 1399px) {
    .top-menu__link {
        padding: 5px 20px;
    }
}

.top-menu__link {
    display: inline-block;
    font-weight: 700;
    color: #fff;
}

.top-menu__link:hover {
    color: #fff !important;
    text-decoration: underline;
}

.ui.star.rating .active.icon {
    color: #21b999!important;
    text-shadow: none !important;
}

.ui.four.doubling.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 30px;
    margin: 0 !important;
}

@media (min-width: 1200px) {
    .ui.four.doubling.cards {
        grid-template-columns: repeat(4, minmax(240px, 1fr));
    }
}

.ui.four.doubling.cards .card {
    width: unset;
    margin: 0 !important;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid {
    display: block !important;
    padding: 30px 15px 60px !important;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column {
    padding: 0 !important;
    width: 100% !important;
    flex-grow: 1;
    flex-shrink: 0;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:first-child .image img {
    border-radius: 6px;
}

@media (max-width: 1199px) {
    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:first-child {
        margin-bottom: 40px !important;
    }
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > * {
    margin: 0 !important;
    padding: 0 !important;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .monster.header {
    font-size: 2.2em;
    border: 0;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .text.menu .item {
    padding-left: 0 !important;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .stackable.grid {
    align-items: center;
    padding: 25px 0 !important;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .stackable.grid .sub.header {
    color: #666;
    font-weight: 400;
}

@media (max-width: 767px) {
    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .stackable.grid {
        display: block !important;
    }

    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .stackable.grid > * {
        text-align: left !important;
        padding: 0 0 5px 0 !important;
    }
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .stackable.grid > * {
    padding: 0;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .segment {
    border: 0;
    box-shadow: none;
    margin-bottom: 30px !important;
    line-height: 3;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child > .segment p {
    font-size: 15px;
    line-height: 1.8;
}

[data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child .form > .button {
    margin-top: 20px;
}

[data-route="sylius_shop_product_show"] .ui.top.attached.large.tabular.menu {
    position: relative;
}

[data-route="sylius_shop_product_show"] .ui.top.attached.large.tabular.menu::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -100%;
    width: 300%;
    height: 2px;
    background: #f5f5f5;
}

[data-route="sylius_shop_product_show"] .ui.top.attached.large.tabular.menu .item {
    border: 0;
    border-bottom: 3px solid transparent;
    padding: 20px 30px;
}

[data-route="sylius_shop_product_show"] .ui.top.attached.large.tabular.menu .item:hover {
    border-color: #d7d7d7;
    border-width: 2px !important;
}

[data-route="sylius_shop_product_show"] .ui.top.attached.large.tabular.menu .item.active {
    border-color: #22b99a;
    border-width: 3px !important;
}

[data-route="sylius_shop_product_show"] .ui.bottom.attached.tab.segment {
    border: 0;
    padding: 30px 0;
    font-size: 15px;
    line-height: 1.8;
}

@media (min-width: 1200px) {
    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid {
        display: flex !important;
    }

    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column {
        flex-basis: 50%;
    }

    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child {
        flex-basis: 50%;
        padding-left: 40px !important;
    }
}

@media (min-width: 1500px) {
    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column {
        flex-basis: 60%;
    }

    [data-route="sylius_shop_product_show"] .ui.two.column.stackable.grid > .column:last-child {
        flex-basis: 40%;
        padding-left: 60px !important;
    }
}

[data-route="sylius_shop_product_index"] .ui.monster.section.dividing.header {
    position: relative;
    max-width: 800px;
    margin: 50px auto 120px auto;
    text-align: center;
    border: 0;
}

[data-route="sylius_shop_product_index"] .ui.monster.section.dividing.header .sub.header {
    margin-top: 20px;
    line-height: 1.7;
}

[data-route="sylius_shop_product_index"] .ui.fluid.vertical.menu .item:not(.header) {
    position: relative;
    padding: 10px 0 10px 26px !important;
    border-radius: 6px;
}

[data-route="sylius_shop_product_index"] .ui.fluid.vertical.menu .item:not(.header)::before {
    content: '•';
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    display: block;
    width: auto;
    height: auto;
    background: transparent;
}

[data-route="sylius_shop_product_index"] .ui.fluid.vertical.menu .item.header {
    font-size: 1.8em;
    margin-bottom: 20px;
    padding: 0 0 18px;
    border-bottom: 2px solid #eee;
}

[data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment {
    padding: 0;
    border: 0;
    box-shadow: none;
}

@media (min-width: 768px) {
    [data-route="sylius_shop_product_index"] .ui.fluid.vertical.menu {
        transform: translateY(86px);
    }

    [data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment {
        background: #eee;
        width: 100%;
        transform: translate(-18%, -70px);
    }
}

@media (min-width: 768px) {
    [data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment {
        width: 900px;
        transform: translate(-122px, -70px);
    }
}

[data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment form > .grid {
    flex-wrap: nowrap !important;
}

[data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment form .column {
    padding-top: 0;
    padding-bottom: 0;
    width: auto !important;
}

[data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment input {
    border: 0;
    padding: 1.04em 1em;
    background: #eee;
}

[data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment .button {
    width: 48px;
    height: 48px;
    margin-left: 5px;
    padding: 0 !important;
    font-size: 0 !important;
    background: #eee !important;
    border: 0 !important;
}

[data-route="sylius_shop_product_index"] .twelve.wide.column .ui.segment .button i {
    width: 48px !important;
    font-size: 16px !important;
    color: #808080;
}

[data-route="sylius_shop_homepage"] .homepage .ui.hidden.divider:first-child {
    margin: 0;
}

[data-route="sylius_shop_homepage"] .homepage-banner {
    position: relative;
    margin-bottom: 110px;
}

[data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__image img {
    width: 100%;
}

@media (max-width: 991px) {
    [data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__content {
        padding: 40px 20px;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.6;
        background: #eee;
        text-align: center;
    }
}

@media (min-width: 992px) {
    [data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__content {
        position: absolute;
        bottom: 70px;
        left: 70px;
        max-width: 500px;
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        line-height: 1.4;
    }

    [data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__content .button {
        background: transparent !important;
        padding: 0 !important;
        font-size: 18px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        text-decoration: underline;
        color: #22b99a !important;
        border: 0 !important;
    }
}

@media (min-width: 1640px) {
    [data-route="sylius_shop_homepage"] .homepage-banner {
        height: 660px;
    }

    [data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1600px;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        border-radius: 0 0 6px 6px;
    }

    [data-route="sylius_shop_homepage"] .homepage-banner .homepage-banner__content {
        bottom: 110px;
        left: 0;
        max-width: 600px;
        font-size: 60px;
        line-height: 1.2;
    }
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment {
    position: relative;
    background: none;
    border: 0;
    box-shadow: none;
    padding: 0;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment.newsletter {
    padding: 90px 0;
    margin: 90px 0;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment.newsletter > .grid > .row {
    display: block !important;
    text-align: center;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment.newsletter > .grid > .row > .column {
    display: block !important;
    width: 100% !important;
    max-width: 800px !important;
    margin: 0 auto;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment.newsletter p {
    line-height: 2;
    font-size: 1.2em;
    margin-bottom: 40px;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment.newsletter::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1600px;
    height: 100%;
    border-radius: 6px;
    background: #eee;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment:last-child {
    padding: 90px 0;
    margin: 70px 0 0 0;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment:last-child p {
    line-height: 2;
    font-size: 1.2em;
}

[data-route="sylius_shop_homepage"] .homepage .secondary.segment:last-child::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 1px;
    background: #eee;
}

[data-route="sylius_shop_homepage"] .ui.huge.header,
.ui.horizontal.section.divider.header {
    display: block;
    font-size: 2.4em !important;
    text-align: center;
    letter-spacing: 0;
}

[data-route="sylius_shop_homepage"] .homepage > .ui.huge.header,
.ui.horizontal.section.divider.header {
    margin-top: 60px;
    margin-bottom: 40px;
}

[data-route="sylius_shop_homepage"] .ui.huge.header::after,
.ui.horizontal.section.divider.header::after {
    all: unset;
    content: "›";
    display: inline-block;
    padding: 0 0 0 15px;
    color: #22b99a;
}

[data-route="sylius_shop_homepage"] .ui.inverted.vertical.footer.segment {
    margin-top: 220px !important;
}

[data-route="sylius_shop_cart_summary"] .ui.two.column.stackable.grid,
[data-route="sylius_shop_cart_save"] .ui.two.column.stackable.grid,
[data-route="sylius_shop_cart_checkout"] .ui.two.column.stackable.grid {
    margin-top: 20px;
    margin-bottom: 10px;
}

[data-route="sylius_shop_cart_summary"] .ui.segment,
[data-route="sylius_shop_cart_save"] .ui.segment,
[data-route="sylius_shop_cart_checkout"] .ui.segment {
    border: 0;
    box-shadow: none;
    padding: 0;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table tbody td,
[data-route="sylius_shop_cart_save"] .column:first-child .table tbody td,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table tbody td,
[data-route="sylius_shop_checkout_complete"] form table tbody td,
[data-route="sylius_shop_account_order_show"] table tbody td {
    border: 0 !important;
    border-bottom: 1px solid #eee !important;
    padding: 24px 0 14px 0 !important;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table thead th,
[data-route="sylius_shop_cart_save"] .column:first-child .table thead th,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table thead th,
[data-route="sylius_shop_checkout_complete"] form table thead th,
[data-route="sylius_shop_account_order_show"] table thead th {
    border: none !important;
    border-bottom: 2px solid #eee !important;
    white-space: nowrap;
    color: #999;
    background: transparent !important;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table th:nth-child(2),
[data-route="sylius_shop_cart_summary"] .column:first-child .table td:nth-child(2),
[data-route="sylius_shop_cart_save"] .column:first-child .table th:nth-child(2),
[data-route="sylius_shop_cart_save"] .column:first-child .table td:nth-child(2),
[data-route="sylius_shop_cart_checkout"] .column:first-child .table th:nth-child(2),
[data-route="sylius_shop_cart_checkout"] .column:first-child .table td:nth-child(2),
[data-route="sylius_shop_checkout_complete"] form table th:nth-child(2),
[data-route="sylius_shop_checkout_complete"] form table td:nth-child(2),
[data-route="sylius_shop_account_order_show"] table th:nth-child(2),
[data-route="sylius_shop_account_order_show"] table td:nth-child(2) {
    text-align: right;
    padding-right: 20px !important;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table th:nth-child(3),
[data-route="sylius_shop_cart_save"] .column:first-child .table th:nth-child(3),
[data-route="sylius_shop_cart_checkout"] .column:first-child .table th:nth-child(3),
[data-route="sylius_shop_checkout_complete"] form table th:nth-child(3),
[data-route="sylius_shop_account_order_show"] table th:nth-child(3) {
    text-align: left !important;
    width: 60px;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table th:nth-child(4),
[data-route="sylius_shop_cart_save"] .column:first-child .table th:nth-child(4),
[data-route="sylius_shop_cart_checkout"] .column:first-child .table th:nth-child(4),
[data-route="sylius_shop_checkout_complete"] form table th:nth-child(4),
[data-route="sylius_shop_account_order_show"] table th:nth-child(4) {
    text-align: right !important;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table .sylius-product-name,
[data-route="sylius_shop_cart_save"] .column:first-child .table .sylius-product-name,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table .sylius-product-name,
[data-route="sylius_shop_checkout_complete"] form table .sylius-product-name,
[data-route="sylius_shop_account_order_show"] table .sylius-product-name {
    font-size: 0.9em !important;
    color: #3f3f3f !important;
    font-weight: 700;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table .image,
[data-route="sylius_shop_cart_save"] .column:first-child .table .image,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table .image,
[data-route="sylius_shop_checkout_complete"] form table .image,
[data-route="sylius_shop_account_order_show"] table .image {
    width: 100px !important;
    border-radius: 5px;
    margin-right: 15px;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table .sub.header,
[data-route="sylius_shop_cart_save"] .column:first-child .table .sub.header,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table .sub.header,
[data-route="sylius_shop_checkout_complete"] form table .sub.header,
[data-route="sylius_shop_account_order_show"] table .sub.header {
    color: #adadad !important;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table .sylius-cart-remove-button,
[data-route="sylius_shop_cart_save"] .column:first-child .table .sylius-cart-remove-button,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table .sylius-cart-remove-button {
    background: transparent;
    border: 1px solid #eee;
}

[data-route="sylius_shop_cart_summary"] .column:first-child .table .sylius-total,
[data-route="sylius_shop_cart_save"] .column:first-child .table .sylius-total,
[data-route="sylius_shop_cart_checkout"] .column:first-child .table .sylius-total {
    font-weight: 700;
}

[data-route="sylius_shop_cart_summary"] .five.column:last-child,
[data-route="sylius_shop_cart_save"] .five.column:last-child,
[data-route="sylius_shop_cart_checkout"] .five.column:last-child {
    padding-left: 50px !important;
}

[data-route="sylius_shop_cart_summary"] .column:last-child .ui.dividing.header,
[data-route="sylius_shop_cart_save"] .column:last-child .ui.dividing.header,
[data-route="sylius_shop_cart_checkout"] .column:last-child .ui.dividing.header {
    border: 0;
    font-size: 1em;
    color: #999;
    margin-top: 15px;
    margin-bottom: 20px;
    padding-bottom: 14px;
    border-bottom: 2px solid #eee;
}

[data-route="sylius_shop_cart_summary"] .column:last-child .table td,
[data-route="sylius_shop_cart_save"] .column:last-child .table td,
[data-route="sylius_shop_cart_checkout"] .column:last-child .table td {
    padding: 16px 0;
}

[data-route="sylius_shop_cart_summary"] .column:last-child .table .header td,
[data-route="sylius_shop_cart_save"] .column:last-child .table .header td,
[data-route="sylius_shop_cart_checkout"] .column:last-child .table .header td {
    padding-top: 30px !important;
}

[data-route="sylius_shop_login"] h1.ui.header {
    margin: 40px 0;
    font-size: 26px !important;
}

[data-route="sylius_shop_login"] .ui.padded.segment .ui.header,
[data-route="sylius_shop_register"] .ui.padded.segment .ui.header {
    font-size: 26px !important;
    margin-top: 20px;
    border: 0;
}

[data-route="sylius_shop_login"] .ui.segment {
    padding: 0;
    border: 0;
    box-shadow: none;
}

@media (min-width: 656px) {
    [data-route="sylius_shop_login"] .ui.segment > .relaxed.grid  {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(655px, 1fr));
        gap: 30px;
        margin: 0 !important;
    }
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid > .column {
    width: unset;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid > .divider {
    display: none;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:first-child {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 30px 60px 70px 60px !important;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:first-child .button {
    margin-top: 20px;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:first-child button.button {
    background: #22b99a;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:last-child {
    padding: 30px 60px 70px 60px !important;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:last-child .button {
    background: #22b99a;
}

[data-route="sylius_shop_login"] .ui.segment > .relaxed.grid .column:last-child .button:hover {
    background: #fff;
    border: 1px solid #22b99a;
}

[data-route="sylius_shop_register"] .ui.header {
    margin: 10px 0 30px 0;
}

[data-route="sylius_shop_register"] .ui.container > .ui.padded.segment {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 30px 60px 70px 60px !important;
}

[data-route="sylius_shop_register"] .ui.container > .ui.padded.segment .ui.header:not(:first-child) {
    margin-top: 60px;
}

[data-route="sylius_shop_register"] .ui.container > .ui.padded.segment button {
    margin-top: 40px;
}

[data-route^="sylius_shop_account_"] .breadcrumb {
    padding-bottom: 20px;
}

[data-route^="sylius_shop_account_"] .ui.grid {
    margin-top: 0;
}

[data-route^="sylius_shop_account_"] .ui.container .ui.grid > .twelve.wide.column:last-child {
    padding-left: 60px;
}

[data-route^="sylius_shop_account_"] .ui.container .ui.grid .column:last-child .ui.segment {
    border: none;
    box-shadow: none;
    padding: 0;
}

[data-route^="sylius_shop_account_"] .ui.container .ui.grid .column:last-child .ui.top.attached.styled.header {
    background: none;
    border: 0;
    padding: 0;
}

[data-route^="sylius_shop_account_"] .ui.fluid.vertical.menu .item {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #eee;
}

[data-route^="sylius_shop_account_"] h1.header {
    margin-top: 10px !important;
    margin-bottom: 20px;
    border: 0 !important;
}

[data-route^="sylius_shop_account_"] h1.header .sub.header {
    padding-top: 15px;
}

[data-route="sylius_shop_account_dashboard"] .ui.large.list {
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 5px;
}

[data-route="sylius_shop_account_dashboard"] .ui.large.list .item {
    padding-bottom: 10px;
}

[data-route^="sylius_shop_checkout_"] .grid .segment,
[data-route^="sylius_shop_checkout_"] .steps + .segment {
    border: 0;
    box-shadow: none;
    padding: 0;
}

[data-route^="sylius_shop_checkout_"] .grid .segments .segment {
    padding: 30px;
}

[data-route^="sylius_shop_checkout_"] .grid > .column:first-child .segment .header  {
    border: 0;
    font-size: 24px;
}

[data-route^="sylius_shop_checkout_"] .ui.container > .segment:first-child .menu {
    margin-top: 40px;
    align-items: center;
}

[data-route^="sylius_shop_checkout_"] .steps {
    margin-bottom: 60px;
    line-height: 1.5 !important;
}

[data-route^="sylius_shop_checkout_"] .steps .active .title,
[data-route^="sylius_shop_checkout_"] .steps .step.completed i::before {
    color: #22b99a !important;
}

[data-route^="sylius_shop_checkout_"] .ui.icon.dropdown.labeled.button {
    background: #eee !important;
}

[data-route="sylius_shop_checkout_complete"] form > .segment,
[data-route="sylius_shop_account_order_show"] .segment {
    padding: 30px !important;
    margin-bottom: 40px !important;
}

[data-route="sylius_shop_account_order_show"] .ui.container .ui.grid .column:last-child .ui.segment {
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15) !important;
    border: 1px solid rgba(34,36,38,.15) !important;
}

[data-route="sylius_shop_checkout_complete"] form > .segment .header,
[data-route="sylius_shop_account_order_show"] .segment .header {
    border: 0;
    font-size: 18px;
}

[data-route="sylius_shop_checkout_complete"] .table,
[data-route="sylius_shop_account_order_show"] .table {
    border: 0;
}

[data-route="sylius_shop_checkout_complete"] .table tfoot td,
[data-route="sylius_shop_checkout_complete"] .table tfoot th,
[data-route="sylius_shop_account_order_show"] .table tfoot td,
[data-route="sylius_shop_account_order_show"] .table tfoot th {
    padding: 20px 0 !important;
    background: transparent;
}

[data-route="sylius_shop_checkout_complete"] .table tfoot tr:last-child td,
[data-route="sylius_shop_account_order_show"] .table tfoot tr:last-child td {
    font-weight: 700;
}

[data-route="sylius_shop_checkout_complete"] .table + .grid .message,
[data-route="sylius_shop_account_order_show"] .table + .grid .message {
    background: #f3f4f5;
    box-shadow: none !important;
    border: 1px solid #e0e0e0;
    padding: 30px;
}

[data-route="sylius_shop_checkout_complete"] .segment .ui.huge.primary.fluid.icon.labeled.button,
[data-route="sylius_shop_account_order_show"] .segment .ui.huge.primary.fluid.icon.labeled.button {
    display: block !important;
    max-width: 500px !important;
    height: 60px;
    margin: 40px auto !important;
}

[data-route^="sylius_shop_checkout_"] .ui.very.basic.table td {
    padding: 16px 0;
}

[data-route^="sylius_shop_checkout_"] .ui.center.aligned.icon.header,
[data-route^="sylius_shop_checkout_"] .ui.center.aligned.icon.header + .divider + .segment {
    background: #f3f4f5;
    padding: 40px 0;
    border-radius: 5px;
}

[data-route^="sylius_shop_checkout_"] .ui.center.aligned.icon.header + .divider {
    display: none;
}

.ui.inverted.vertical.footer.segment {
    background: #1E2E3E;
    margin-top: 300px !important;
}

.ui.inverted.vertical.footer.segment .segment {
    background: transparent;
}

.ui.inverted.vertical.footer.segment .column {
    box-shadow: none !important;
}

.ui.inverted.vertical.footer.segment .item {
    padding: 7px 0;
}

.pre-footer {
    align-items: center;
    max-width: 1600px;
    margin: -250px auto 110px auto;
    border-radius: 6px;
    background: #22b99a;
}

.pre-footer > div {
    padding: 20px 20px;
    text-align: center;
}

@media (min-width: 1000px) {
    .pre-footer {
        display: flex;
        justify-content: space-around;
        height: 260px;
        padding: 0 120px;
    }

    .pre-footer > div {
        max-width: 400px;
    }
}

.pre-footer > div > * {
    display: block;
    font-size: 18px;
    line-height: 1.8;
    opacity: 0.7;
}

.pre-footer > div > strong {
    opacity: 1 !important;
}

.pre-footer > div > div:first-child {
    display: inline-block;
    font-size: 36px;
    padding-bottom: 10px;
}

label {
    display: inline-block !important;
    font-size: 16px !important;
    margin: 15px 0 0 0 !important;
    padding: 0 0 10px 0 !important;
    line-height: 1.4 !important;
}

.toggle label {
    padding-left: 60px !important;
}

input, select {
    padding-top: 0.95em !important;
    padding-bottom: 0.95em !important;
}

input:focus {
    border-color: #22b99a !important;
}

select.ui.dropdown {
    height: auto !important;
}

.ui.button,
.ui.basic.button,
.ui.labeled.button,
.ui.labeled.icon.button,
.buttons .ui.labeled.icon.button {
    display: inline-block;
    background: transparent !important;
    font-size: 15px !important;
    border-radius: 4px !important;
    border: 1px solid #e1e1e1 !important;
    padding: 14px 24px !important;
    color: #3d3d3d !important;
    box-shadow: none !important;
}

.ui.labeled.icon.button,
.buttons .ui.labeled.icon.button {
    padding-left: 62px !important;
}

.ui.button.primary,
.ui.basic.button.primary,
.ui.labeled.button.primary,
.ui.labeled.icon.button.primary,
.buttons .ui.labeled.icon.button.primary,
.ui.button.green,
.ui.basic.button.green,
.ui.labeled.button.green,
.ui.labeled.icon.button.green,
.buttons .ui.labeled.icon.button.green,
.ui.button.submit,
.ui.basic.button.submit,
.ui.labeled.button.submit,
.ui.labeled.icon.button.submit,
.buttons .ui.labeled.icon.button.submit {
    background: #22b99a !important;
    color: #fff !important;
    border-color: #22b99a !important;
}

.dimmer .ui.button,
.ui.button.slick-arrow {
    color: #fff !important;
}

.ui.button:hover,
.ui.basic.button:hover,
.ui.labeled.button:hover,
.ui.labeled.icon.button:hover,
.buttons .ui.labeled.icon.button:hover {
    background: #22B99A2C !important;
    color: #22b99a !important;
    border-color: #22B99A45 !important;
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: #22b99a !important;
}

address {
    font-style: normal;
    line-height: 1.6;
}

.text-teal {
    color: #22b99a;
}

.address-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

@media (min-width: 1500px) {
    .address-cards {
        grid-template-columns: 1fr 1fr;
    }
}

.address-card--default {
    margin-bottom: 20px;
}

.address-card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    border: 1px solid rgba(34, 36, 38, 0.1);
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.03) !important;
    padding: 25px;
    border-radius: 6px;
}

.address-card-content.default {
    border-color: #22b99a;
}

.address-card .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.address-label {
    position: absolute;
    top: -1px;
    right: -1px;
    background: #22b99a;
    color: #fff;
    padding: 5px 10px;
    border-radius: 0 0 0 5px;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
}

.address-card .buttons .ui.button,
.address-card .buttons .ui.basic.button,
.address-card .buttons .ui.labeled.button,
.address-card .buttons .ui.labeled.icon.button,
.address-card .buttons .buttons .ui.labeled.icon.button {
    padding: 6px 10px 6px 0 !important;
}

.address-card .buttons .ui.button .icon,
.address-card .buttons .ui.basic.button .icon,
.address-card .buttons .ui.labeled.button .icon,
.address-card .buttons .ui.labeled.icon.button .icon,
.address-card .buttons .buttons .ui.labeled.icon.button .icon {
    position: relative !important;
    width: 30px !important;
    padding: 7px 0 !important;
}

.text-break {
    word-break: break-word;
}
